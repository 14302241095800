import React, { PropsWithChildren } from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import theme, { GlobalStyle } from 'theme';

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <EmotionThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </EmotionThemeProvider>
);

export default ThemeProvider;
